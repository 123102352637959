import SearchModel from "../../../domain/entities/search/SearchModel";
import UserClaimOfficeStatusModel from "../../../domain/entities/usersettings/UserClaimOfficeStatusModel";
import { useState, useEffect } from "react";
import SearchApi from "../../../data/search/SearchApi";
import { HttpClient } from "../../../infrastructure/utils/fetchInterceptor";
import UserSettingApi from "../../../data/usersettings/UserSettingApi";
import { useSelector } from 'react-redux';
import { RootState } from "../../../infrastructure/redux/reducer";
import { UseFormGetValues } from "react-hook-form";
import SelectOption from "../../models/SelectOption";

type createSearchState = {
    firstName: string;
    lastName: string;
    dateOfBirth: string;
    ageRangeFrom: string;
    ageRangeTo: string;
    dateOfLoss: string;
    address: string;
    city: string;
    state: string;
    zipCode: string;
    employer: string;
    caseName: string;
    newCaseName: string;
    caseOption: string;
    currentCaseId: number | null;
    isClaimOfficeRequired: boolean;
    mustFillClaimOffice: boolean;

    emails: string[];
    phones: string[];
    usernames: string[];

    stateList: SelectOption[] | undefined;
    caseList: SelectOption[] | undefined;
    selectedCase?: SelectOption | undefined;
    selectedState: SelectOption | undefined;
};

export const ExistCase = 'ExistCase';
export const NewCase = 'NewCase';

function useSearchViewModel() {

    const [state, setState] = useState<createSearchState>({
        currentCaseId: null,
        caseName: '',
        newCaseName: '',
        caseOption: NewCase,
        firstName: '',
        lastName: '',
        dateOfBirth: '',
        dateOfLoss: '',
        ageRangeFrom: '',
        ageRangeTo: '',
        address: '',
        city: '',
        state: '',
        zipCode: '',
        employer: '',
        isClaimOfficeRequired: false,
        mustFillClaimOffice: false,

        emails: [''],
        phones: [''],
        usernames: [''],

        caseList: undefined,
        stateList: undefined,
        selectedState: undefined,
        selectedCase: undefined, 
    });

    const [isLoading, setIsLoading] = useState(false);
    const [isShowError, setIsShowError] = useState(false);
    const [errorMessages, setErrorMessages] = useState<[string]>();
    const [isSuccess, setIsSuccess] = useState(false);

    const searchRepository = new SearchApi(new HttpClient());
    const settingsRepository = new UserSettingApi(new HttpClient());

    const ReRunSearch = useSelector((state: RootState) => state.search);

    useEffect(() => {
        if (state.currentCaseId && state.caseList) {
            const caseName = state.caseList?.find((e: { value: number, label: string }) => e.value == state.currentCaseId)?.label || '';
            if (caseName)
                setState(prevState => ({ ...prevState, caseName: caseName, selectedCase: { value: prevState.currentCaseId ?? 0, label: caseName } }));
            else
                //case may not exist in case list if user rerun another peson's search, each user can get and see only his cases
                setState(prevState => ({ ...prevState, caseName: '', selectedCase: undefined }));
        }
    }, [state.caseList, state.currentCaseId])

    const prepareData = (caseId: string) => {
        if (caseId)
            onCaseIdQueryParamChanged(caseId);

        if (ReRunSearch.caseId !== null) {
            initializeSearchWithRereunSearch(ReRunSearch);
            onCaseIdQueryParamChanged(ReRunSearch.caseId.toString());
        }

        getUserClaimOfficeData();
        listStates();
        listCases();
    }

    const onCaseIdQueryParamChanged = (caseId: string): void => setState(prevState => ({ ...prevState, currentCaseId: parseInt(caseId), caseOption: ExistCase }));

    const getUserClaimOfficeData = async () => {
        try {
            const result = await settingsRepository.getUserClaimOfficeStatus();
            if (result.isSuccess) {
                const value = result.value as UserClaimOfficeStatusModel;
                setState(prevState => ({ ...prevState, isClaimOfficeRequired: value.isClaimOfficeRequired, mustFillClaimOffice: value.mustFillClaimOffice }));
            }
        }
        catch (e: any) {
            setIsLoading(false);
            setIsShowError(true);
            setErrorMessages([e.message]);
        }
    }

    const listCases = async (): Promise<void> => {
        try {
            setIsLoading(true);
            const result = await searchRepository.get();
            if (result.isSuccess)
                setState(prevState => ({ ...prevState, caseList: result?.value?.map((c) => { return { value: c.caseId, label: c.name } })}));
            setIsLoading(false);
            setIsShowError(!result.isSuccess);
            if (!result.isSuccess) setErrorMessages([result.error]);
        } catch (e: any) {
            setIsLoading(false);
            setIsShowError(true);
            setErrorMessages([e.message]);
        }
    };

    const listStates = () => setState(prevState => ({ ...prevState, stateList: searchRepository.getStates().map(s => { return { value: s.id, label: s.name } }), state: prevState.selectedState?.label ?? '' }));

    const onClickSearch = async (data: createSearchState, event: any): Promise<void> => {
        
        try {
            const model = {} as SearchModel;
            model.EnhancedSearchEnabled = event?.nativeEvent?.submitter?.name == "enhancedSearch";
            model.CaseId = data.currentCaseId ?? (data.selectedCase?.value || null);
            model.NewCaseName = data.newCaseName;
            model.FirstName = data.firstName;
            model.LastName = data.lastName;
            model.DateOfLoss = new Date(data.dateOfLoss);
            model.DateOfBirth = new Date(data.dateOfBirth);
            model.AgeRangeFrom = data.ageRangeFrom ? parseInt(data.ageRangeFrom) : null;
            model.AgeRangeTo = data.ageRangeTo ? parseInt(data.ageRangeTo) : null;
            if (data.emails && data.emails[0]) model.Emails = data.emails;
            else model.Emails = [];
            if (data.phones && data.phones[0]) model.Phones = data.phones;
            else model.Phones = [];
            if (data.usernames && data.usernames[0]) model.Usernames = data.usernames;
            else model.Usernames = [];
            model.Address = data.address;
            model.City = data.city;
            model.State = data.state;
            model.ZipCode = data.zipCode;
            model.Employer = data.employer;
            setIsLoading(true);
            let result;
            if (window.location.pathname === "/create-request")
                result = await searchRepository.addFakeRequest(model);
            else
                result = await searchRepository.add(model);
            setIsLoading(false);
            setIsShowError(!result.isSuccess);
            errorMessages ? errorMessages.push(result.error) : setErrorMessages([result.error]);
            setErrorMessages(errorMessages);
            setIsSuccess(result.isSuccess);
            if (result.isSuccess) window.location.href = "/search";
        } catch (e: any) {
            setIsLoading(false);
            errorMessages ? errorMessages.push(e.message) : setErrorMessages([e.message]);
            setIsShowError(true);
        }
    };

    const initializeSearchWithRereunSearch = (reRunSearch: ReRunSearchState) => {
        setState(prevState => ({
            ...prevState,
            firstName: reRunSearch.firstName,
            lastName: reRunSearch.lastName,
            dateOfBirth: reRunSearch.dateOfBirth,
            ageRangeFrom: reRunSearch.ageRangeFrom,
            ageRangeTo: reRunSearch.ageRangeTo,
            dateOfLoss: reRunSearch.dateOfLoss,
            address: reRunSearch.address,
            city: reRunSearch.city,
            state: reRunSearch.state,
            zipCode: reRunSearch.zipCode,
            employer: reRunSearch.employer,
            currentCaseId: reRunSearch.caseId,
            emails: reRunSearch.emailFormValues,
            phones: reRunSearch.phoneFormValues,
            usernames: reRunSearch.usernameFormValues,
            selectedState: searchRepository.getStates().filter(s => s.name == reRunSearch.state)?.map(s => ({ value: +s.id as number, label: s.name as string }))?.at(0),
        }));
    }

    const oneOfRequiredFieldsIsEntered = (getValues: UseFormGetValues<createSearchState>) => {
        const emailExist: boolean = getValues("emails").length > 0 && getValues("emails")?.at(0) != '';
        const phoneExist: boolean = getValues("phones").length > 0 && getValues("phones")?.at(0) != '';
        const usernameExist: boolean = getValues("usernames").length > 0 && getValues("usernames")?.at(0) != '';
        const addressAndZipcodeExist: boolean = getValues("address") != undefined && getValues("zipCode") != undefined && getValues("address") != '' && getValues("zipCode") != '';

        return emailExist || phoneExist || usernameExist || addressAndZipcodeExist;
    }

    const dateOfBirthOrAgeRangeIsEntered = (getValues: UseFormGetValues<createSearchState>) => (getValues("ageRangeFrom") != '' && getValues("ageRangeTo") != '') || getValues("dateOfBirth") != '';
    const ageRangeIsValid = (getValues: UseFormGetValues<createSearchState>, value: string) => !value || +value < +getValues("ageRangeTo");

    return {
    state,
    isLoading,
    isSuccess,
    isShowError,
    errorMessages,
    onClickSearch,
    prepareData,
    oneOfRequiredFieldsIsEntered,
    dateOfBirthOrAgeRangeIsEntered,
    ageRangeIsValid
   }
}

export default useSearchViewModel;
