import React, { useEffect, useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import CompanyApi from "../../../data/admin/CompanyApi";
import { HttpClient } from "../../../infrastructure/utils/fetchInterceptor";
import CompanyNameModel from "../../../domain/entities/admin/models/CompanyNameModel";

type Props = {
    disabled?: boolean,
    selectedCompanyId?: number,
    companyNames: CompanyNameModel[],
    onChange: (id: number | undefined) => void
}

const LinkedToCompany: React.FC<Props> = (props: Props) => {

    const [state, setState] = useState({isValid: true});

    const onChange = (e: React.FormEvent) => {
        const id = (e as React.FormEvent<HTMLInputElement>).currentTarget.value;
        if (!id) {
            setState({ ...state, isValid: false });
            props.onChange(undefined);
        }
        else {
            setState({ ...state, isValid: true });
            props.onChange(+id);
        }
    }

    return (
        <Row className="info-section">
            <Row className="titile-row">
                <Col md="6"><span className="title">Company Name</span><span className="mandatory-symbol">*</span></Col>
            </Row>
            <Row className="data-row">
                <Col md="6">
                    <select disabled={props.disabled} id="selectedCompanyId"
                        className={`form-control ${!state.isValid ? "is-invalid company-select" : "company-select"}`}
                        onChange={onChange}>
                        <option selected={!props.selectedCompanyId} value=''></option>
                        {props.companyNames.map(c => <option selected={c.id == props.selectedCompanyId} value={c.id} key={c.id}>{c.name}</option>)}
                    </select>
                    {!state.isValid && (
                        <div className="invalid-feedback">Company can not be empty</div>
                    )}
                </Col>
            </Row>
        </Row>
    );
}

export default LinkedToCompany;