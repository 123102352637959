import React, { useEffect } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { TailSpin } from "react-loader-spinner";
import AcceptTerms from "../components/registration/AcceptTerms_new";
import AccountInfo from "../components/registration/AccountInfo_new";
import GlbaDppa from "../components/registration/GlbaDppa_new";
import SuccessfulRegistrationRequest from "../components/registration/SuccessfulRegistrationRequest";
import useRegisterViewModel from "../view-model/auth/RegisterViewModel_new";
import { useForm } from "react-hook-form";

const Register: React.FC = () => {

    const {
        state,
        DPPAOptionId,
        GLBAOptionId,
        isLoading,
        isSuccess,
        isShowError,
        errorMessages,
        showCancelModal,
        showGlbaDppaWarningModal,
        glbaDppaSelectedOptionWarning,

        resetIsShowError,
        hideCancelModal,
        cancelRegistration,
        onCancel,
        register,
        getData,
        onEmailChange,
        hideGlbaDppaWarningModal,
        onGlbaChanged,
        onDppaChanged,

        displayState,
        onAcceptTermsNext,
        onAccountInfoNext,
        onAccountInfoPrev,
        onGlbaDppaPrevious

    } = useRegisterViewModel();

    const { control, handleSubmit, reset, formState: { errors, isValid }, watch, setValue, getValues } = useForm({ defaultValues: state, mode: "onTouched" });

    useEffect(() => { getData() }, []);
    useEffect(() => { reset(state) }, [state]);

    const onEmailChangeAction = (email: string) => onEmailChange(email, setValue);

    const introStatement = <>
        <div className="row registration-title"><span className="p-0">Registration</span></div>
        <div className="row intro">Fill out the required fields in the registration form below so you can get access to your system. Registration info is usually reviewed within 24 hours.</div>
    </>;

    return <>
        {!isSuccess &&
            <>
            <form id="registration-form" onSubmit={handleSubmit(register)}>
                <div className={!displayState.acceptTerms ? 'd-none' : ''} >
                    <AcceptTerms
                        control={control}
                        watch={watch}
                        introStatement={introStatement}
                        onNext={onAcceptTermsNext}
                        onCancel={onCancel}
                    />
                </div>
                <div className={!displayState.accountInfo ? 'd-none' : ''}>
                    <AccountInfo
                        introStatement={introStatement}
                        control={control}
                        setValue={setValue}
                        getValues={getValues}
                        formIsValid={isValid}
                        state={state}
                        watch={watch}
                        onEmailChange={onEmailChangeAction}
                        onNext={onAccountInfoNext}
                        onPrevious={onAccountInfoPrev}
                        onCancel={onCancel}
                    />
                </div>
                <div className={!displayState.glbaDppa ? 'd-none' : ''}>
                    <GlbaDppa
                        introStatement={introStatement}
                        onPrevious={onGlbaDppaPrevious}
                        GLBAOptionId={GLBAOptionId}
                        DPPAOptionId={DPPAOptionId}
                        onGlbaChanged={onGlbaChanged}
                        onDppaChanged={onDppaChanged}
                        onCancel={onCancel}
                        showGlbaDppaWarningModal={showGlbaDppaWarningModal}
                        hideGlbaDppaWarningModal={hideGlbaDppaWarningModal}
                        glbaDppaSelectedOptionWarning={glbaDppaSelectedOptionWarning}
                    />
                </div>
            </form>
                <Modal show={showCancelModal}>
                    <Modal.Body className="modal-card cancel-modal-card">
                        <div className="cancel-modal">
                            <Row className="cancel-modal-header">
                                <Col md="auto" className="p-0" ><img src="/images/exclamation-circle.svg" /></Col>
                                <Col ><span className="cancel-modal-title">Cancel Registration?</span></Col>
                                <Col md="auto" className="close-modal-icon"><span onClick={hideCancelModal}>x</span></Col>
                            </Row>
                            <Row>
                                <p className="cancel-modal-body p-0">If you cancel your registration now you will lose your data</p>
                            </Row>
                            <Row className="cancel-modal-btn-row">
                                <Col md="auto" className="p-0"><a onClick={hideCancelModal} className="btn previous-btn">No, continue registration</a></Col>
                                <Col className="p-0"><a onClick={cancelRegistration} className="btn next-btn">Yes, cancel registration</a></Col>
                            </Row>
                        </div>
                    </Modal.Body>
                </Modal>
                <TailSpin
                    wrapperClass="cover-spin"
                    visible={isLoading}
                    height={50}
                    width="50"
                    color="#FF5733"
                    ariaLabel="loading"
                />
                <Modal show={isShowError}>
                    <Modal.Body className="modal-card warning-modal-card error-modal registration-error-modal">
                        <div>
                            <Row className="warning-modal-header">
                                <Col md="auto" className="p-0" ><img src="/images/exclamation-circle.svg" /></Col>
                                <Col ><span className="cancel-modal-title">Server Error</span></Col>
                                <Col md="auto" className="close-modal-icon"><span onClick={resetIsShowError}>x</span></Col>
                            </Row>
                            <Row> {errorMessages.map(e => <p key={e} className="p-0 warning-modal-body m-2">{e}</p>)} </Row>
                            <Row className="warning-modal-btn-row">
                                <Col className="p-0"><a onClick={resetIsShowError} className="btn warning-btn">Try Again Later</a></Col>
                            </Row>
                        </div>
                    </Modal.Body>
                </Modal>
            </>
        }
        {isSuccess && !isShowError && <SuccessfulRegistrationRequest />}
    </>

};

export default Register;
