import React from "react";
import { Col, Row } from "react-bootstrap";
import AuthorizedManagerCheckBox from "./AuthorizedManagerCheckBox_new";
import AdminCheckBox from "./AdminCheckBox_new";
import CustomInput from "../CustomInput";
import { emailRegex, phoneRegex } from "../../../infrastructure/lookups/RegEx";
import { Control, UseFormWatch } from "react-hook-form";
import PersonInfoEditState from "../../models/PersonInfoEditState";

type Props = {
    editableEmail: boolean,
    isAuthorizedManagerEnabled: boolean,
    isAdminEnabled: boolean,
    control: Control<PersonInfoEditState, any>,
    watch?: UseFormWatch<PersonInfoEditState>,
    onEmailChange?: (email: string) => void,
}

const PersonalInformation: React.FC<Props> = ({ editableEmail, isAuthorizedManagerEnabled, isAdminEnabled, control, watch, onEmailChange }: Props) => {

    const emailNotExist = watch && watch("emailNotExist");

    return <>
        <Row className="ml-n-25px section">
            <Col md="auto" className="section-title">Personal Information</Col>
            <Col><hr /></Col>
        </Row>
        <Row className="row-style">
            <Col className="input-group-meta form-group mb-30 form-floating first-col-input">
                <CustomInput
                    name="firstName"
                    placeholder="First Name"
                    control={control}
                    required
                    hasRequiredSymbol
                    requiredMessage="First Name can not be empty"
                />
            </Col>
            <Col className="input-group-meta form-group mb-30 form-floating first-col-input">
                <CustomInput
                    name="lastName"
                    placeholder="Last Name"
                    control={control}
                    required
                    hasRequiredSymbol
                    requiredMessage="Last Name can not be empty"
                />
            </Col>
        </Row>
        <Row className="row-style">
            <Col className="input-group-meta form-group form-floating first-col-input">
                <CustomInput
                    name="phoneNumber"
                    placeholder="Phone"
                    control={control}
                    required
                    hasRequiredSymbol
                    requiredMessage="Phone can not be empty"
                    pattern={{ value: phoneRegex, message: "Phone is not valid" }}
                />
            </Col>
            <Col className="input-group-meta form-group form-floating first-col-input">
                <CustomInput
                    name="email"
                    placeholder="Email"
                    control={control}
                    required
                    hasRequiredSymbol
                    requiredMessage="Email can not be empty"
                    pattern={{ value: emailRegex, message: "Email is not valid" }}
                    disabled={!editableEmail}
                    validate={_ => !editableEmail || emailNotExist || "We're having trouble processing your registration with this email address.Please email our support team at intertel@intertelgo.com for further assistance."}
                    onFieldChange={onEmailChange}
                />
            </Col>
        </Row>
        <Row className="mb-30">
            {isAuthorizedManagerEnabled && <AuthorizedManagerCheckBox control={control} />}
            {isAdminEnabled && <AdminCheckBox control={control} />}
        </Row>
    </>
};

export default PersonalInformation;
