import React from "react"

type Props = {
    email: string
}

const NoMatchingCompanyNotification: React.FC<Props> = (props: Props) => {
    const domain = props.email.split('@')[1];


    return <div className="d-flex justify-content-end no-matching-company-notification">
        
        <div >The specific domain name &quot;{domain}&quot; does not match any existing company.</div>
        <div>
            <a href={`/admin/company/create?domains=${domain}`}>Add Company</a>
        </div>
    </div>
}


export default NoMatchingCompanyNotification;
