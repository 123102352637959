import React, { useState } from "react";

type props = {
    collapsed?: boolean
}


const TermsOfService: React.FC<props> = ({collapsed = false, ...props}) => {

    const [state, setState] = useState({ collapsed: collapsed, collapseBtnText: 'Read more..' });

    const onCollapseBtnClick = () => {
        if (state.collapsed)
            setState({ collapsed: false, collapseBtnText: 'Show less' })
        else
            setState({ collapsed: true, collapseBtnText: 'Read more..' })
    }

    return (
        <div className="privacy-policy--terms-and-conditions color-grey">
            <p className="terms-title">
                Terms And Conditions
            </p>
            <p>
                <b>If you or your organization and INTERTEL, Inc. (the &quot;Company&quot;) have entered into a
                written agreement for the use of Company&apos;s Services (such as a Master Services
                Agreement), then that written agreement supersedes these Terms of Use. For users
                that have not entered into a written agreement with Company, the following terms
                and conditions will apply to your use of the Services (as defined below).</b>
            </p>
            <p>
                By accessing or using the Services, You agree to the following Terms of Use in addition to
                the <a href="https://intertelinc.com/privacy-policy/">Privacy Policy</a>. <b><u>You should review these Terms and the Privacy Policy regularly as
                    they may change at any time at the sole discretion of Company.</u></b> If you do not agree to
                any portion of these Terms, you should not access or otherwise use the Ser vices. NOTICE
                OF ANY SUCH CHANGE WILL BE GIVEN BY THE POSTING OF A NEW VERSION OR A
                CHANGE NOTICE ON THE WEBSITE. IT IS YOUR RESPONSIBILITY TO REVIEW THIS
                AGREEMENT AND THE PRIVACY POLICY PERIODICALLY. IF AT ANY TIME YOU FIND
                EITHER UNACCEPTABLE, YOU MUST IMMEDIATELY LEAV{collapsed && state.collapsed && <><span>...</span>&nbsp;&nbsp;&nbsp;<span onClick={onCollapseBtnClick} className="collapseBtn">{state.collapseBtnText}</span></>}<span className={state.collapsed ? 'd-none' : ''}>E THE WEBSITE AND REFRAIN
                FROM USING THE SERVICES.</span>
            </p>
            <span className={state.collapsed ? 'd-none' : ''}>            
            <p>
                1. SERVICES 
            </p>
            <p>
                Company provides social media and background research, utilizing information
                collected via direct inquiry, Third-Party data solutions, and the Internet. Social media
                research is conducted by utilizing information provided by clients and Third-Party data
                aggregators, to locate various social media profiles and online information about a
                particular individual. Company provides our clients with a conduit to view publicly
                available information as provided via the so-called World Wide Web. This information
                may include images, videos, sound, user shared and generated content, and other
                material from various Internet domains. The information is captured and generated in
                various computer formats such as MHTML, WARC, PDF, JPG, MP4 and others. Reliance of
                the contents of this publicly available data is at the user&apos;s sole discretion and risk and is
                presented &apos;as is&apos;, with no warranties, express or implied. Social Media Services as well as
                any online features, services and/or programs offered by Company will be collectively
                referred to as &quot;Services&quot; herein. 
            </p>
            <p>
                2. CHANGE ORDERS 
            </p>
            <p>
                If, after Company begins work, you decide you want the work done differently than as
                specified in the original instructions then you shall be solely responsible for providing
                revised instructions to Company. If Company incurs costs in conjunction with following
                any revised instructions, whether through re-work, abandoned work, or additional work,
                then you shall pay an additional charge consistent with the new work and with the
                original contract pricing.
            </p>
            <p>
                3. FEES 
            </p>
            <p>
                By ordering reports through the Website, You agree to pay Company for Services at the
                rates as agreed to the time of the order. Payment is due for goods and services no later
                than thirty days from the date of the invoice date. Payment to Company shall not in any
                way be contingent upon your receiving payment from your client or from any Third-Party
                and shall be totally independent of any agreement that you might have with your client
                or Third-Party. You will pay a late fee of one percent (1.0%) per month (or the highest rate
                allowed under the law, whichever is lower), on any overdue amounts. Overdue invoices
                may also result in suspension from Services until all overdue amounts are paid. You
                hereby agree to pay all costs of collection, including reasonable attorneys&apos; fees and court
                costs, incurred by Company if legal action is instituted against You for breach of your
                payment responsibilities herein. All fees are exclusive of applicable sales, excise, use or
                similar taxes. 
            </p>
            <p>
                4. USER CREDENTIALS
            </p>
            <p>
                In connection with Your use of the Services, You will be required to complete a
                registration form. You represent and warrant that all information You provide on any
                registration form or otherwise in connection with Your use of the Services will be
                complete and accurate, and that You will maintain and promptly update that
                information as necessary to keep it complete and accurate.
                You will also be asked to provide, or may be given, a username and password in
                connection with Your use of the Services. You authorize Company to rely on your
                username and password to identify you when you use the Services. You shall take all
                such actions as are necessary to maintain the confidentiality of, and to prevent the
                unauthorized use of, Your username and password. You agree to notify Company if you
                become aware of the unauthorized use of Your username and password.
                You may not use the account or password of any other Services user. You agree to notify
                Company immediately of any unauthorized use of Your account or password, including
                any use by former employees. Company shall not be liable for any loss that You incur as a
                result of someone else using Your account or password, regardless of whether that use is
                with or without Your knowledge. You may be held liable for any losses incurred by
                Company, its affiliates, officers, directors, employees, consultants, agents, and
                representatives due to someone else&apos;s use of Your account or password, including use by
                former employees.
            </p>
            <p>
                5. CONFIDENTIALITY 
            </p>
            <p>
                &quot;Confidential Information&quot; means any and all information provided or disclosed by a
                Party to the other Party or any information either Party gains access to through its
                relationship with each other that a reasonable person would understand to be
                confidential (including pricing). The Parties (including all Users and, if applicable, the
                designated payor) shall keep Confidential Information in strict confidence and shall use
                Confidential Information only in furtherance of this Agreement protect and maintain the
                confidentiality and security of Confidential Information with at least the same degree of
                care as it normally exercises to protect its own confidential, proprietary and/or trade
                secret information of a similar nature, but in any case using no less than a reasonable
                degree of care. 
            </p>
            <p>
                6. WARRANTY AND SECURITY 
            </p>
            <p>
                Company will perform all Services in a professional and workmanlike manner. Company
                agrees to protect all personally identifiable information and protected health
                information that is disclosed in connection with the Services and to comply with all
                applicable state and federal privacy and data security laws and regulations. Company
                will at all times maintain reasonable and appropriate safeguards to protect Confidential
                Information. EXCEPT AS EXPRESSLY SET FORTH HEREIN, Company DISCLAIMS ANY
                AND ALL EXPRESS OR IMPLIED WARRANTIES RELATED TO THE SERVICES.
            </p>
            <p>
                7. CONSENT TO RECEIVE EMAIL
            </p>
            <p>
                By establishing an account with Company, You consent to receive periodic email
                communications from Company concerning Company&apos;s services and other matters.
            </p>
            <p>
                8. PROHIBITED USES 
            </p>
            <p>
                You acknowledge that the Services contain highly sensitive information, including
                Personally Identifiable Information (PII) and Personal Health Information (PHI), to which
                no one, other than intended recipients should have access. Accordingly, Company
                imposes certain restrictions on Your use of the Services. You represent and warrant that
                You will not: (a) violate or attempt to violate any security features of the Services; (b)
                access content or data not intended for You, or log onto a server or account that You are
                not authorized to access; (c) distribute (including, without limitation, through email) the
                records made available to You via the Services; (d) attempt to probe, scan, or test the
                vulnerability of the Services, or any associated system or network, or breach any security
                or authentication measures without proper authorization; (e) interfere or attempt to
                interfere with the use of the Services by any other user, host or network, including,
                without limitation by means of submitting a virus, overloading, &quot;flooding,&quot; &quot;spamming,&quot;
                &quot;mail bombing,&quot; or &quot;crashing&quot; the website or infrastructure that provides the Services.
                Any breach of this Section will result in the violation of the privacy rights of third-parties,
                and may subject You to civil and/or criminal liability. Company will cooperate with law
                enforcement in any investigation of any violation of this or any other Section of this
                Agreement.
            </p>
            <p>
                9. SOCIAL MEDIA TERMS. 
            </p>
            <p>
                The Company is materially compliant with the terms of use and other Contracts, including all
                policies and guidelines incorporated therein, applicable to their use of any Social Media
                Platforms (&quot;Social Media Terms&quot;). No provider of any Social Media Platform has ever
                prevented the Company from (i) offering any products or services on any Social Media
                Platform, or (ii) otherwise maintaining any presence on any Social Media Platform, or
                threatened to do either (i) or (ii).
            </p>
            <p>
                10. LIMITATION OF LIABILITY 
            </p>
            <p>
                <b><u>In no event shall either party&apos;s liability in any action arising under or relating to this
                    Agreement exceed the total fees paid in the previous twelve (12) month period. In no
                    event shall either party be liable to the other party for any indirect, consequential, or
                    incidental damages, regardless of the theory of law.</u></b>
            </p>
            <p>
                11. GOVERNING LAW; ARBITRATION
            </p>
            <p>
                This Agreement shall be interpreted and governed by the laws of the State of Texas
                without regard to its rules regarding conflict of laws. By using the Website or Services,
                You agree that any claim, dispute, or controversy arising from or relating to this
                Agreement <b><u>must be resolved by arbitration.</u></b> 
            </p>
            <p>
                12. AGREEMENT 
            </p>
            <p>
                BY USING THE SERVICES, YOU REPRESENT AND WARRANT THAT YOU ARE AT
                LEAST 18 YEARS OLD, ARE A RESIDENT OF THE UNITED STATES, AND ARE OTHERWISE
                LEGALLY QUALIFIED TO ENTER INTO AND FORM CONTRACTS UNDER APPLICABLE
                LAW. If You are using the Website or Services on behalf of a company, You further
                represent and warrant that You are authorized to act and enter into contracts on behalf
                of that company. This Agreement is void where prohibited.&nbsp;&nbsp;&nbsp;{collapsed && !state.collapsed && <span onClick={onCollapseBtnClick} className="collapseBtn">{state.collapseBtnText}</span>}
            </p>
            </span>
            <div className="height-50"></div>
        </div>
    );
};

export default TermsOfService;
